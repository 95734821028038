<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->

		<div class="row">
			<div class="col-md-9">

				<div class="card mt-3 shadow">
					<div class="card-header white">
						<h6>商品基础资料</h6>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="">
							
							
							<div class="form-row">
								<div class="col-md-6 mb-3">
									<label for="">分类</label>
									<select class="form-control" v-model="product.category_id">
										<option v-bind:key="index" :value="item.id"
											v-for="(item,index) in categorylist">{{item.category_name}}</option>
									</select>
								</div>
								<div class="col-md-6 mb-3">
									<label for="">品名</label>
									<input v-model="product.product_name" type="text" class="form-control"
										placeholder="品名,不超过8个字">
									<div class="valid-feedback">
										Looks good!
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-md-6 mb-3">
									<label for="">价格(元)</label>
									<input v-model="product.price" type="number" class="form-control" placeholder="单价">
								</div>
								<div class="col-md-6 mb-3">
									<label for="">规格单位</label>
									<input v-model="product.unit" type="text" class="form-control"
										placeholder="规格单位,如：份、杯、盅等">
									<small class="form-text text-muted">
									</small>
								</div>
							</div>
							
							<button v-if="product.id>0" @click="deleteProduct" type="button" class="btn btn-dark"> 删除 </button>
							<button @click="save" :disabled="disabled" type="button" class="btn btn-primary ml-2"> 保存 </button>


						<!-- <div class="form-row">
							<div class="col-md-3 mb-3">
								<label for="">分类</label>
								<select class="form-control" v-model="product.category_id">
									<option v-bind:key="index" :value="item.id"  v-for="(item,index) in categorylist">{{item.category_name}}</option>
								</select>
							</div>
							<div class="col-md-3 mb-3">
								<label for="">品名</label>
								<input v-model="product.product_name" type="text" class="form-control"  placeholder="品名,不超过8个字">
								<div class="valid-feedback">
									Looks good!
								</div>
							</div>
							<div class="col-md-3 mb-3">
								<label for="">价格(元)</label>
								<input v-model="product.price" type="number" class="form-control" placeholder="单价">
							</div>
							<div class="col-md-3 mb-3">
								<label for="">规格单位</label>
								<input v-model="product.unit" type="text" class="form-control" placeholder="规格单位,如：份、杯、盅等">
								<small class="form-text text-muted">
								</small>
							</div>
						</div> -->

							<!-- <div class="form-row">
								<div class="col-md-4 mb-3">
									<label for="">商品分类*</label>
									<select class="form-control" v-model="product.category_id">
										<option v-bind:key="index" :value="item.id"
											v-for="(item,index) in categorylist">{{item.category_name}}</option>
									</select>
								</div>

								<div class="col-md-4 mb-3">
									<label for="">商品名称*</label>
									<input v-model="product.product_name" type="text" class="form-control"
										placeholder="输入唯一的商品名称">
									<div class="s-12">
										商品名称8个字以内，不允许含有特殊字符和表情符
									</div>
								</div>

								<div class="col-md-4 mb-3">
									<label for="">商品描述</label>
									<input v-model="product.description" type="text" class="form-control"
										placeholder="输入商品描述">
								</div>
							</div> -->


							<!-- <div class="form-row">
								<div class="col-md-4 mb-3">
									<label for="">商品TAG</label>
									<input v-model="product.tag" type="text" class="form-control" placeholder="输入商品TAG">

									<div class="s-12">
										最多四个字，如:强烈推荐、销量冠军、进店必点
									</div>
								</div>

								<div class="col-md-4 mb-3">
									<label for="">餐盒单价(元)</label>
									<input v-model="product.lunch_box_price" type="number" class="form-control"
										placeholder="餐盒单价">
								</div>
								<div class="col-md-4 mb-3">
									<label for="">餐盒数量</label>
									<input v-model="product.lunch_box_qty" type="number" class="form-control"
										placeholder="餐盒数量">
								</div>

							</div> -->

							<!-- <button v-if="product.id>0" @click="remove" type="button" class="btn btn-dark"> 删除 </button> -->
							<!-- <button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button> -->

						</div>
					</div>

				</div>


				<!-- //// -->
				<div class="card my-3 shadow no-b r-0" v-if="false">
					<div class="card-header white">
						<div class="row my-3">
							<div class="col-md-6">
								<h6>
									商品规格
								</h6>
								<h6 class="s-12">
									<!-- 非称重菜品支持多规格，如大小份 -->
									{{product.product_type && !product.scale_flag?'非称重菜品支持多规格，如大小份':'单位与价格'}}
								</h6>
							</div>
							<div class="col-md-6">
								<div class="float-right">
									<button 
									v-if="product.product_type && !product.scale_flag"
									@click="editSpes" class="btn btn-dark btn-outline-primary btn-sm"
										type="button">
										{{specList.length &lt; 2 ?'添加规格':'修改规格'}}

									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="">
							<!-- 单规格 -->
							<div class="form-row" v-if="specList.length<2">
								<div class="col-md-4">
									<label for="">商品单位*</label>
									<input v-model="product.unit" type="text" class="form-control" placeholder="输入单位"
										required="">
								</div>
								<div class="col-md-4">
									<label for="">标价（元）*</label>
									<input v-model="product.price" type="number" class="form-control" placeholder="输入价格"
										required="">
								</div>
								
							</div>

							<!-- 多规格 -->
							<div v-if="product.product_type && !product.scale_flag && specList.length>1">
								<div class="form-row" v-for="(item, index) in specList" v-bind:key="index">
									<div class="col-md-4">
										<label for="" v-if="index == 0">商品单位*</label>
										<input v-model="item.unit" type="text" readonly=""
											class="form-control-plaintext">
									</div>
									<div class="col-md-4">
										<label for="" v-if="index == 0">标价（元）*</label>
										<input v-model="item.price" type="text" readonly=""
											class="form-control-plaintext">
									</div>
									
								</div>
							</div>

						</div>
					</div>
				</div>
				<!-- //// -->

				<!-- //// -->
				<!-- <div class="card my-3 shadow no-b r-0" v-if="product.product_type"> -->
				<div class="card my-3 shadow no-b r-0" v-if="false">
					<div class="card-header white">
						<div class="row my-3">
							<div class="col-md-6">
								<h6>
									商品偏好
								</h6>
								<h6 class="s-12">
									如辣度、甜度、温度等
								</h6>
							</div>
							<div class="col-md-6">
								<div class="float-right">
									<button @click="editTastes" class="btn btn-dark btn-outline-primary btn-sm"
										type="button">
										{{tasteList.length>0?'修改偏好':'添加偏好'}}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body" v-if="tasteList.length>0">
						<div class="needs-validation" novalidate="">

							<div class="form-row" v-for="(item, index) in tasteList" v-bind:key="index">
								<div class="col-md-3">
									<label for="" v-if="index == 0"> 偏好名称</label>
									<input v-model="item.tag" type="text" readonly="" class="form-control-plaintext">
								</div>
								<div class="col-md-6">
									<label for="" v-if="index == 0"> 偏好<span
											class="s-12">（不同偏好用英文逗号隔开）</span></label>
									<input v-model="item.values" type="text" readonly="" class="form-control-plaintext">
								</div>
								
							</div>

						</div>
					</div>
				</div>
				<!-- //// -->


				<!-- //// -->
				<!-- <div class="card my-3 shadow no-b r-0" v-if="product.product_type"> -->
				<div class="card my-3 shadow no-b r-0" v-if="false">
					<div class="card-header white">
						<div class="row my-3">
							<div class="col-md-6">
								<h6>
									商品加料
								</h6>
								<h6 class="s-12">
									表示这个菜品，可以添加的加料，一种加料，一种价格
								</h6>
							</div>
							<div class="col-md-6">
								<div class="float-right">
									<button @click="editAttachs" class="btn btn-dark btn-outline-primary btn-sm"
										type="button">
										{{attachList.length>0?'修改加料':'添加加料'}}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body" v-if="attachList.length>0">
						<div class="needs-validation" novalidate="">

							<div class="form-row" v-for="(item, index) in attachList" v-bind:key="index">
								<div class="col-md-3">
									<label for="" v-if="index == 0"> 加料菜名</label>
									<input v-model="item.name" type="text" readonly="" class="form-control-plaintext">
								</div>
								<div class="col-md-6">
									<label for="" v-if="index == 0"> 加料价格(元)</label>
									<input v-model="item.price" type="text" readonly="" class="form-control-plaintext">
								</div>
								
							</div>

						</div>
					</div>
				</div>
				<!-- //// -->

			</div>


			<div class="col-md-3 mt-3">
				<div class="form-row">

					<div class="col-md-12 mb-3">
						<label for="" class="font-weight-bold s-14">商品图片</label>
						<!-- <input hidden id="file" name="file"/>
						<div class="dropzone dropzone-file-area pt-4 pb-4" id="fileUpload">
							<div class="dz-default dz-message">
								<span>Drop A passport size image of user</span>
								<div>You can also click to open file browser</div>
							</div>
						</div> -->
						<VueUploadImgs 
							compress
							:before-read="beforeRead"
							:after-read="afterRead"
							:before-remove="beforeRemove"
							:limit="limit"
							:type="type"
							@preview="preview"
							@exceed="exceed"
							@oversize="oversize"
							v-model="files"
						>
						</VueUploadImgs>
						<div class="mt-2 s-12">图片格式为jpg,jpeg,gif,png，正方形，推荐尺寸为480x480像素</div>
					</div>
					

				</div>

				<div class="card mt-4"  v-if="false">
					<h6 class="card-header white">商品选项</h6>
					<div class="card-body p-0">

						<ul class="list-group no-b">
							<li class="list-group-item d-flex justify-content-between align-items-center">
								<div>
									售卖状态
								</div>
								<div class="material-switch">
									<input @click="onProductOptionChange('active')" type="checkbox"
										:checked="product.active=='ON'" id="s1" name="s1">
									<label for="s1" class="bg-primary"></label>
								</div>
							</li>

							<li class="list-group-item d-flex justify-content-between align-items-center">
								<div>
									是否菜品
								</div>
								<div class="material-switch">
									<input @click="onProductOptionChange('product_type')" type="checkbox"
										:disabled="specList.length >1" 
										:checked="product.product_type==1" id="s2" name="s2">
									<label for="s2" class="bg-primary"></label>
								</div>
							</li>
							<li v-if="product.product_type==1"
								class="list-group-item d-flex justify-content-between align-items-center">
								<div>
									是否称重
								</div>
								<div class="material-switch">
									<input @click="onProductOptionChange('scale_flag')" type="checkbox"
										:disabled="specList.length >1"
										:checked="product.scale_flag==1" id="s3" name="s3">
									<label for="s3" class="bg-primary"></label>
								</div>
							</li>

							<li v-if="product.product_type==1"
								class="list-group-item d-flex justify-content-between align-items-center">
								<div>
									是否厨房打印
								</div>
								<div class="material-switch">
									<input @click="onProductOptionChange('kitchen_print')" type="checkbox"
										:checked="product.kitchen_print==1" id="s4" name="s4">
									<label for="s4" class="bg-primary"></label>
								</div>
							</li>



						</ul>
					</div>
					<!-- <div class="card-footer bg-transparent">
					<button class="btn btn-primary btn-sm" type="submit">Publish</button>
				</div> -->
				</div>


			</div>

		</div>

		<div class="form-group" v-if="false">
			<button v-if="product.id>0" @click="deleteProduct" type="button" class="btn btn-dark"> 删除 </button>
			<button @click="save" :disabled="disabled" type="button" class="btn btn-primary ml-2"> 保存 </button>
		</div>

		<!-- //main content -->


		<!-- modal -->
		<!-- 商品规格 -->
		<div class="modal fade" id="specModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">商品规格</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="row">
								<div class="col">
									<h6>不同规格主要是价格不同</h6>
								</div>
								<div class="col">
									<div class="float-right">
										<button @click="addSpec" type="button" class="btn btn-dark btn-sm"> 添加规格 </button>
									</div>
								</div>
							</div>
							<hr>

							<div class="form-row" v-for="(item, index) in tempSpecList" v-bind:key="index">
								<div class="col-md-4 mb-3">
									<label class="font-weight-bold">规格名称{{index+1}}</label>
									<input v-model="item.unit" class="form-control" placeholder="如大份、中杯、9寸" type="text">
								</div>

								<div class="col-md-4 mb-3">
									<label>菜品标价(元)</label>
									<input v-model="item.price" class="form-control" placeholder="输入价格" type="number">
								</div>


								<div class="col-md-4 mb-3 mt-4 pt-2">
									<i @click="deleteSpec(index)" class="icon icon-trash red-text"></i>
								</div>
							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveSpec" type="button" class="btn btn-primary"> 保存 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- 商品偏好 -->
		<div class="modal fade" id="tasteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">偏好</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="row">
								<div class="col">
									<h6>不同偏好用英文逗号隔开</h6>
								</div>
								<div class="col">
									<div class="float-right">
										<button @click="addTaste" type="button" class="btn btn-dark btn-sm"> 添加偏好
										</button>
									</div>
								</div>
							</div>
							<hr>

							<div class="form-row" v-for="(item, index) in tempTasteList" v-bind:key="index">
								<div class="col-md-4 mb-3">
									<label class="font-weight-bold">偏好名称{{index+1}}</label>
									<input v-model="item.tag" class="form-control" placeholder="如:辣度" type="text">
								</div>

								<div class="col-md-4 mb-3">
									<label>偏好名称</label>
									<input v-model="item.values" class="form-control" placeholder="如:不辣,微辣,中辣"
										type="text">
								</div>


								<div class="col-md-4 mb-3 mt-4 pt-2">
									<i @click="deleteTaste(index)" class="icon icon-trash red-text"></i>
								</div>
							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveTaste" type="button" class="btn btn-primary"> 保存 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- 商品加料 -->
		<div class="modal fade" id="attachModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">菜品加料</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="row">
								<div class="col">
									<h6>表示这个菜品，可以添加的加料，一种加料，一种价格</h6>
								</div>
								<div class="col">
									<div class="float-right">
										<button @click="addAttach" type="button" class="btn btn-dark btn-sm"> 添加加料
										</button>
									</div>
								</div>
							</div>
							<hr>

							<div class="form-row" v-for="(item, index) in tempAttachList" v-bind:key="index">
								<div class="col-md-4 mb-3">
									<label class="font-weight-bold">加料菜名{{index+1}}</label>
									<input v-model="item.name" class="form-control" placeholder="如粉丝、芒果、椰奶" type="text">
								</div>

								<div class="col-md-4 mb-3">
									<label>加料价格(元)</label>
									<input v-model="item.price" class="form-control" placeholder="输入加料价格" type="number">
								</div>


								<div class="col-md-4 mb-3 mt-4 pt-2">
									<i @click="deleteAttach(index)" class="icon icon-trash red-text"></i>
								</div>
							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveAttach" type="button" class="btn btn-primary"> 保存 </button>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 图片预览 -->
		<div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">商品图片预览</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body center">
		
						<div class="m-3">
		
							<img :src="previewIMG" class="preview-img">
		
						</div>
		
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal"> 关闭 </button>
					</div>
				</div>
			</div>
		</div>
		
		

		<!-- //modal -->
	</div>
</template>

<script>
	import VueUploadImgs from '../components/VueUploadImgs.vue'
	
	export default  {
		data() {
			return {
				title: '创建商品',

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',

				product: {
					"product_id": "",
					"point_exchange_enabled": 0,
					"discount_enabled": 1,
					"min_purchase_quantity": 1,
					"tastes": "",
					"point_enabled": 0,
					"bottom_stock": "",
					"origin": "",
					"description": "",
					"supply_price": "",
					"item_no": "",
					"category_id": 0,
					"price": "",
					"vip_discount_type": 1,
					"point_rate": 1,
					"gift_enabled": 0,
					"barcode": "",
					"commission_type": 0,
					"exchange_point": "",
					"makings2": "",
					"commission_amount": "",
					"kitchen_print": 1,
					"active": "ON",
					"scale_flag": 0,
					"top_stock": "",
					"product_name": "",
					"is_inventory": 0,
					"brand_id": 0,
					"attachs": "",
					"unit": "份",
					"product_type": 1,
					"have_cust_prop": 0,
					"outlet_id": 0,
					"img_url": "",
					"commission_rate": "",
					"vip_price": "",
					"supplier_id": 0,
					"stock_qty": "",
					"tag": "",
					"lunch_box_price": 0,
					"lunch_box_qty": 0
				},

				disabled: false,

				categorylist: [],
				category: {}, //选中的会员级别

				setting: {},

				//以下扫码点餐商品
				specList: [],
				tempSpecList: [], //临时

				tasteList: [],
				tempTasteList: [],

				attachList: [],
				tempAttachList: [],

				outletId: 0, //登录店铺IDs
				productImg: '',

				selectedOutletId: 0,
				selectedOutletName: '',
				selectedCatId: 0,
				selectedCatName: '',
				selectedBrandId: 0,
				selectedBrandName: '',
				selectedSupplierId: 0,
				selectedSupplierName: '',
				selectedVipDiscountTypeDesc: '售价*会员折扣率',
				selectedCommissionTypeDesc: '无提成',

				old_product_name: '',
				old_barcode: '',

				isMore: false,
				
				//图片上传
				files: [],
				maxSize: 1024 * 10, // 10 KB
				previewIMG: null,
				limit: 1,
				isPreview: false,
				type: 2, // 0 预览模式 1 列表模式 2 预览模式 + 上传按钮
			}
		},

		components: {
			VueUploadImgs
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->', this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;

			self.setting = JSON.parse(localStorage.getItem('SETTING'));

			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)

			//指定店铺
			// let selectedOutletId = getQueryString("outlet_id");
			console.log('--- this.$route>', this.$route)
			let selectedOutletId = this.$route.params.outlet_id;
			if (selectedOutletId) {
				self.outlet_id = selectedOutletId;
			}

			//是否编辑
			let selectedProductBarcode = this.$route.query.no;
			if (selectedProductBarcode) {
				var _params = this.$route.query;
				// self.selectedOutletId = _params.outlet_id;
				self.resetProduct(_params);
			}
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }

			self.initData();
		},
		methods: {
			initData() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getCyCategoryList', {
						params: {
							token: self.token,
							outlet_id: self.outlet_id,
							// name: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.categorylist = data.data;

							if (self.categorylist.length > 0) {
								if(!self.product.id){//新增时默认第一个
									self.product.category_id = self.categorylist[0].id || 0; 
								}
							} else {
								// alert('该店铺没有商品分类');
								self.$toasted.error('该店铺没有商品分类', {
									position: 'top-center',
								}).goAway(2000)
							}

							// __LIST = data.data;
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END

			},

			//添加(用于消费机)
			add() {
				let self = this;
				console.log(self.product);
				// return;

				//校验
				if (self.product.category_id == 0) {
					// alert('请选择商品分类')
					self.$toasted.error('请选择商品分类', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.product.product_name) {
					// alert('品名不能为空')
					self.$toasted.error('品名不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.product.price) {
					// alert('价格不能为空')
					self.$toasted.error('价格不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.product.unit) {
					// alert('规格不能为空')
					self.$toasted.error('规格不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}


				self.disabled = true;

				// let tags = $(".tags-input").tagsinput('items');
				// let tag = tags.length>0?tags.join():'';

				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,

					product_name: self.product.product_name,
					price: self.product.price || 0,
					unit: self.product.unit,
					category_id: self.product.category_id,

					kitchen_print: 0,
					scale_flag: 0,
				}

				this.axios.get(this.GLOBAL.baseURI + 'addCyProduct', { //调用简易录菜接口
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							// alert('保存成功')
							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)
							self.$router.push({
								path: 'products',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.initData();
			},

			//以下扫码点餐

			// 规格
			addSpec() {
				let self = this;

				self.tempSpecList.push({
					id: 0,
					unit: '',
					item_no: '',
					price: '',
					vip_price: '',
					supply_price: ''
				})
			},


			editSpes() {
				let self = this;

				self.tempSpecList = Object.assign([], self.specList);

				if (self.tempSpecList.length == 0) {
					self.tempSpecList.push({
						id: 0,
						unit: self.product.unit,
						item_no: '',
						price: self.product.price,
						vip_price: '',
						supply_price: ''
					})
				}

				$('#specModal').modal('show');
			},

			deleteSpec(index) {
				let self = this;

				self.tempSpecList.splice(index, 1);
			},

			saveSpec() {
				let self = this;
				console.log('--- tempSpecList @ saveSpec->', self.tempSpecList);
				// return;

				//校验
				for (let i = 0; i < self.tempSpecList.length; i++) {
					let item = self.tempSpecList[i];
					console.log('--- item  -> ', item)
					if (item.unit == '') {
						// alert('第' + (i + 1) + '个规格名称为空')
						self.$toasted.error('第' + (i + 1) + '个规格名称为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}

					if (!item.price) {
						// alert('第' + (i + 1) + '个规格价格为空')
						self.$toasted.error('第' + (i + 1) + '个规格价格为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}
				// return;


				// console.log('---  tempSpecList ->',JSON.stringify(self.tempSpecList))

				self.specList = Object.assign([], self.tempSpecList);

				if (self.specList.length == 1) {
					self.product.unit = self.specList[0].unit;
					self.product.price = self.specList[0].price;
				}

				$('#specModal').modal('hide');

			},

			// 偏好
			addTaste() {
				let self = this;

				if (self.tempTasteList.length >= 5) {
					// alert('最多可设置5种偏好')
					self.$toasted.error('最多可设置5种偏好', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.tempTasteList.push({
					name: '',
					values: ''
				})
			},


			editTastes() {
				let self = this;

				self.tempTasteList = Object.assign([], self.tasteList);
				// self.tempTasteList.push({
				// 	name: '',
				// 	values: ''
				// })

				$('#tasteModal').modal('show');
			},

			deleteTaste(index) {
				let self = this;

				self.tempTasteList.splice(index, 1);
			},

			saveTaste() {
				let self = this;
				console.log('--- tempTasteList @ saveTaste->', self.tempTasteList);
				// return;

				//校验
				for (let i = 0; i < self.tempTasteList.length; i++) {
					let item = self.tempTasteList[i];
					console.log('--- item  -> ', item)
					if (item.name == '') {
						// alert('第' + (i + 1) + '个偏好名称为空')
						self.$toasted.error('第' + (i + 1) + '个偏好名称为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}

					if (!item.values) {
						// alert('第' + (i + 1) + '个偏好为空')
						self.$toasted.error('第' + (i + 1) + '个偏好为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}
				// return;


				// console.log('---  tempTasteList ->',JSON.stringify(self.tempTasteList))

				self.tasteList = Object.assign([], self.tempTasteList);

				$('#tasteModal').modal('hide');

			},


			// 加料
			editAttachs() {
				let self = this;

				self.tempAttachList = Object.assign([], self.attachList);
				// self.tempAttachList.push({
				// 	name: '',
				// 	price: ''
				// })

				$('#attachModal').modal('show');
			},

			addAttach() {
				let self = this;

				// if(self.tempAttachList.length>=5){
				//  alert('最多可设置10种加料')
				//  return;
				// }

				self.tempAttachList.push({
					name: '',
					price: ''
				})
			},

			deleteAttach(index) {
				let self = this;

				self.tempAttachList.splice(index, 1);
			},

			saveAttach() {
				let self = this;
				console.log('--- tempAttachList @ saveAttach >', self.tempAttachList);
				// return;

				//校验
				for (let i = 0; i < self.tempAttachList.length; i++) {
					let item = self.tempAttachList[i];
					console.log('--- item  -> ', item)
					if (item.unit == '') {
						// alert('第' + (i + 1) + '个加料菜名为空')
						self.$toasted.error('第' + (i + 1) + '个加料菜名为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}

					if (!item.price) {
						// alert('第' + (i + 1) + '个加料价格为空')
						self.$toasted.error('第' + (i + 1) + '个加料价格为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}
				// return;

				self.attachList = Object.assign([], self.tempAttachList);

				$('#attachModal').modal('hide');

			},

			// 商品选项
			onProductOptionChange(key) {
				console.log('--- key  -> ', key)
				let self = this;

				if (key == 'active') {
					self.product[key] = self.product[key] == 'ON' ? 'OFF' : 'ON';
				} else {
					self.product[key] = self.product[key] == 1 ? 0 : 1;
				}
				
				if(key == 'scale_flag' && self.product.scale_flag == 1){
					self.product.unit = '公斤';
				}
				else{
					self.product.unit = '份';
				}

				console.log('--- product  -> ', self.product)
			},

			onstatechange(state) {
				console.log('--- state  -> ', state)
			},

			save() {
				let self = this;
				console.log(self.product);
				// return;


				//0.构建product对象数据
				self.buildProduct();
				console.log('--- product after build->', self.product)
				
				//1.校验
				if (self.product.category_id == 0) {
					// alert('请选择商品分类')
					self.$toasted.error('请选择商品分类', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.product.product_name) {
					// alert('品名不能为空')
					self.$toasted.error('品名不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				// if (!self.product.price) {
				// 	alert('价格不能为空')
				// 	return;
				// }

				// if (!self.product.unit) {
				// 	alert('规格不能为空')
				// 	return;
				// }

				if (self.product.have_cust_prop == 0) {
					if (!self.product.price) {
						// alert('标价不能为空')
						self.$toasted.error('标价不能为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}

					if (!self.product.unit) {
						// alert('单位不能为空')
						self.$toasted.error('单位不能为空', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}

				//非菜品，提交
				if (self.product.product_type == 0) {
					//3.提交
					self.saveProduct();
				} else {
					//2.菜品校验同名
					self.checkProductName();
				}
			},

			//保存提交前，构建product
			buildProduct() {
				let self = this;
				
				//0.商品图片
				// console.log('--- files  -> ', this.$refs.vueUploadImg)
				

				//1.设置独立的值
				// self.product["outlet_id"] = self.outlet_id;
				// self.product["category_id"] = self.selectedCatId;//用默认
				// self.product["brand_id"] = self.selectedBrandId;
				// self.product["supplier_id"] = self.selectedSupplierId;

				//2.设置餐饮数组
				//2.1.规格
				if (self.product.product_type == 1 && self.specList.length > 1) {
					self.product["have_cust_prop"] = 1;
					var idArr = [],
						unitArr = [],
						itemNoArr = [],
						priceArr = [],
						vipPriceArr = [],
						supplyPriceArr = [];
					for (var spec of self.specList) {
						idArr.push(spec.id);
						unitArr.push(spec.unit);
						itemNoArr.push(spec.item_no);
						priceArr.push(spec.price);
						vipPriceArr.push(spec.vip_price);
						supplyPriceArr.push(spec.supply_price);
					}

					self.product["unit_arr"] = unitArr.join(",");
					self.product["item_no_arr"] = itemNoArr.join(",");
					self.product["price_arr"] = priceArr.join(",");
					self.product["vip_price_arr"] = vipPriceArr.join(",");
					self.product["supply_price_arr"] = supplyPriceArr.join(",");

					self.product["id_arr"] = idArr.join(",");

				} else {
					self.product["have_cust_prop"] = 0;

					delete self.product["unit_arr"];
					delete self.product["item_no_arr"];
					delete self.product["price_arr"];
					delete self.product["vip_price_arr"];
					delete self.product["supply_price_arr"];

					delete self.product["id_arr"];
				}

				//2.2.加料
				if (self.product.product_type == 1 && self.attachList.length > 0) {
					var attachs = [];
					for (var attach of self.attachList) {
						var _attach = {
							"name": attach.name,
							"price": attach.price
						};
						attachs.push(_attach);
					}
					console.log('---- attachs after build product->', JSON.stringify(attachs))
					self.product["attachs"] = encodeURI(JSON.stringify(attachs)); //【重要】必须encodeURI
				} else {
					self.product["attachs"] = '';
				}

				//2.3.偏好
				if (self.product.product_type == 1 && self.tasteList.length > 0) {
					self.product["tastes"] = encodeURI(JSON.stringify(self.tasteList));
				} else {
					self.product["tastes"] = '';
				}

				//3.默认值得处理:数字型,null或空串转为0,
				for (var key in self.product) {
					var value = self.product[key];
					if (!value && (
							key == 'commission_amount' || key == 'commission_rate' ||
							key == 'exchange_point' || key == 'stock'
						)) {
						self.product[key] = 0;
					}
				}

				//4.加上old_barcode
				self.product["old_barcode"] = self.old_barcode;

				//5.设置快捷商品码，用时间戳+2位随机数，仅限于新版餐饮
				self.product["barcode"] = new Date().getTime() + "" + parseInt(Math.random() * 100);
			},

			//校验同名
			checkProductName() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'checkProductName', {
						params: {
							token: self.token,
							outlet_id: self.outlet_id,
							product_name: self.product.product_name,
							old_product_name: self.old_product_name
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						if (data.code == 200) {
							if (data.data.is_exist) {
								// alert('该商品名称已存在,请修改');
								
								self.$toasted.error('该商品名称已存在,请修改', {
									position: 'top-center',
								}).goAway(2000)
								
								return
							} else {
								//3.提交
								self.saveProduct();
							}
						} else {
							// alert(data.message);
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}

					});
				//END

			},

			//保存商品
			saveProduct() {
				let self = this;

				self.disabled = true;

				// 组装数据
				let postData = self.product;

				postData['token'] = self.token;
				postData["outlet_id"] = self.outlet_id;
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}
				


				//调用接口
				this.axios.post(this.GLOBAL.baseURI + 'saveCyProduct', 
						postData2
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log('--- error->', error);
						// alert('保存失败')
						self.$toasted.error('保存失败', {
							position: 'top-center',
						}).goAway(2000)
						
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							// alert('保存成功')
							
							self.$toasted.success('保存成功', {
								position: 'top-center',
							}).goAway(2000)
							
							self.$router.push({
								path: 'products',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
				//END
			},

			//重置商品
			resetProduct(params) {
				console.log('--- 编辑商品，params ->',params)
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getCyProductsByBarcode', {
						params: {
							token: self.token,
							// outlet_id: params.outlet_id,
							outlet_id: self.outlet_id,
							barcode: params.no,
							nonce_str: params.nonce_str,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						console.log('--- getCyProductsByBarcode --- ,res->', data);
						
						if (data.code == 200) {
							var _specs = [],
								_tastes = [],
								_attachs = [];

							var products = data.data.products;if (products.length == 0) return;

							var _product = products[0];

							self.product = Object.assign({}, _product); //重置product
							self.product["product_id"] = _product.id;
							self.product["img_url"] = "";

							//菜品处理 start
							if (_product.product_type == 1) {

								// 多规格处理，组装specList
								if (products.length > 1) {
									for (var p of products) {
										var _spec = {};
										_spec["id"] = p.id;
										_spec["unit"] = p.unit;
										_spec["item_no"] = p.item_no;
										_spec["price"] = p.price;
										_spec["vip_price"] = p.vip_price;
										_spec["supply_price"] = p.supply_price;
										_specs.push(_spec);
									}
								}

								console.log("--- spes list->", _specs);

							} else {
								//关闭菜品特有的项
							}
							//菜品处理 end

							//商品图片处理
							// self.productImg, = _product.img_url == 'HAVE' ? baseImgURI + _product.id +
							// 	'.jpg?x-oss-process=style/sm-150x150&' + Math.random() : '',
							
							if(_product.img_url == 'HAVE'){
								self.files.push({
									url: self.GLOBAL.baseImgURI + _product.id +'.jpg?x-oss-process=style/sm-150x150&' + Math.random(),
									name: _product.id +'.jpg'
								})	
							}
							
							//规格、偏好、加料
							self.specList = _specs;
							self.tasteList = _product.tastes;
							self.attachList = _product.attachs;
							
							self.old_product_name = _product.product_name;
							self.old_barcode = _product.barcode;
						}
						 else if (data.code == 404) {
							 // Vue.toasted.error( '未找到商品', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '404'});
							 
							 return;
						 }
						 else {
							// alert(data.message);
							
							// Vue.toasted.error( data.message, {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}

					});
				//END

			},
			
			deleteProduct(){
				let self  = this;
				
				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'deleteCyProduct', {
						params: {
							token: self.token,
							outlet_id: self.product.outlet_id,
							product_id: self.product.product_id,
							del_same_barcode: 1,//是否删除同码多规格的所有商品
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
				
						if (data.code == 200) {
							// alert('删除成功');
							
							// Vue.toasted.success( '删除成功', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							self.$toasted.success('删除成功', {
								position: 'top-center',
							}).goAway(2000)
							
							self.$router.push({
								path: 'products',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message);
							// Vue.toasted.error( data.message, {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
				
					});
				//END
			},
			
			//图片上传
			oversize(file) {
				console.log('oversize')
				console.log('filesize:' + file.size / 1024 + 'KB')
			},
	
			afterRead(file) {
				console.log('after-read')
				console.log(file)
				
				
			},
	
			beforeRemove(index, file) {
				console.log(index, file)
				// delete this.product["img_file"];
				this.product["img_file"] = null;
				this.product["img_url"] = 'remove';
				
				return true
			},
	
			preview(index, file) {
				let url = file.url;//<img class="preview-img" src="https://oss-pk-test.oss-cn-shenzhen.aliyuncs.com/51118960.jpg?x-oss-process=style/sm-150x150&amp;0.836985165951373">
				let newurl = url.indexOf('aliyuncs.com')!=-1?url.replace('sm-150x150', 'w480'):url;
				
				this.previewIMG = newurl;
				this.isPreview = true
				
				
				$('#previewModal').modal('show');
			},
	
			exceed() {
				// alert(`只能上传${this.limit}张图片`)
				
				this.$toasted.error('只能上传${this.limit}张图片', {
					position: 'top-center',
				}).goAway(2000)
			},
	
			beforeRead(files) {
				console.log('before-read')
				for (let i = 0, len = files.length; i < len; i++) {
					const file = files[i]
					if (file.type != 'image/jpeg' && file.type != 'image/png') {
						// alert('只能上传jpg和png格式的图片')
						
						this.$toasted.error('只能上传jpg和png格式的图片', {
							position: 'top-center',
						}).goAway(2000)
						
						$('#cModal').modal('hide');
						
						return false
					}
				}
				
				console.log('--- files  -> ', files[0])
				this.product["img_file"] = files[0];
				this.product["img_url"] = "HAVE";
				
	
				return true
			},
	
			
		},


	}
</script>

<style scoped>
	
</style>
